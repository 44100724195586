import React from 'react'
import Lottie from 'lottie-react';

export default function Loader({loading}) {
  return (
    <>
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-modalBackGround"></div>
              <div className="relative">
                <Lottie className="w-20 mx-auto" animationData={require('../../assets/loader.json')} />
              </div>
          </div>
        )}
    </>
  )
}
