import React,{useCallback, useContext,useState} from 'react'
import { CartContext } from '../../context/Cart'
import GroceryItemCard from '../../ui-components/Cards/GroceryItemCard';
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { useMutation, gql, useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import { QueryProductsBySubCategory, QuerySubCategories, QueryDefaultCustomerType } from "../../apollo/queries";
import UseCookie from '../../utils/cookies/useCookie';
import Footer from '../Footer/Footer';
import { LocationContext } from '../../context/Location';
import PremuimBanner from '../../ui-components/PremuimBanner/PremuimBanner';

const QUERYSUBCATEGORIES = gql`
  ${QuerySubCategories}
`;

const getDefaultCustomerType = gql`
${QueryDefaultCustomerType}
`;

const QUERYPRODUCTSBYSUBCATEGORIES = gql`
  ${QueryProductsBySubCategory}
`;

export default function SubCategories() {
    const { cartItems, addToCart,removeFromCart, callCartData } = useContext(CartContext)
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
    const navigateScreenTo = useNavigate();
    const client = useApolloClient();
    const { location } = useContext(LocationContext)
    var [refreshing, setRefreshing] = useState(false);
    var [isScrolling, setIsScrolling] = useState(false);
    var [isLoading, setIsLoading] = useState(false);
    var [cartData, setCartData] = useState([]);
    var [totalCartItems, setTotalCartItems] = useState([]);
    var [totalActualPrice, setTotalActualPrice] = useState([]);
    var [pageNumber, setPageNumber] = useState(0);
    var [totalRecords, setTotalRecords] = useState(0);
    var [totalPages, setTotalPages] = useState(0);
    var [selectedCategory, setSelectedCategory] = useState(false);
    var [premuimBanner,setPremuimBanner] = useState(false)
    var [generalCustomerType, setGeneralCustomerType] = useState(false);
    var [defaultCustomerType,setDefaultCustomerType] = useState()
    var [loginInfo,setLoginInfo] = useState()
    const navigationLocation = useLocation();
    const { id } = useParams();
    const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();

    
    var [selectedCategory, setSelectedCategory] = useState(0);

    const selectItem = (category) => {
      selectedCategory = category
      setSelectedCategory(selectedCategory);
      if (selectedCategory) {
        refetch({
          variables: {
            "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
            "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
            "rows": 10,
            "columns": 10,
            "subCategoryId": selectedCategory?.itemId
          }
        })
      }
  
    };

    React.useEffect(() => {
      if(navigationLocation?.state?.itemId || id){
        subCategoryFetch({
          variables: {
            "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
            "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
            "rows": 2,
            "columns": 2,
            "categoryId": navigationLocation?.state?.itemId || id
          }
        })
      }
      else{
        navigateScreenTo('/')
      }
    }, [navigationLocation?.state,id])

    

  const [subCategoryFetch, { data, loading, error }] = useLazyQuery(QUERYSUBCATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: (fetchMoreResult) => {
      if (fetchMoreResult) { 
        selectedCategory = fetchMoreResult?.querySubCategories?.items[0]
        setSelectedCategory(selectedCategory);
        onRefresh()
      }
    },
  });


  const [refetch, {  data: SCData, loading: SCLoading, error: SCError }] = useLazyQuery(QUERYPRODUCTSBYSUBCATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: async(fetchMoreResult) => {
      await verifyLoginInfo()
      if(loginInfo){
        generalCustomerType = await getCustomCookie('generalCustomerType')
        console.log("🚀 ~ onCompleted:async ~ generalCustomerType:", generalCustomerType)
        setGeneralCustomerType(generalCustomerType)
      }
      isLoading = false
      setIsLoading(isLoading)
      refreshing = false
      setRefreshing(refreshing);
    },
  });

  const verifyLoginInfo = async()=>{
    loginInfo = await getCustomCookie('loginInfo')
    setLoginInfo(loginInfo)
    // let defaultCustomerType =  await getCustomCookie('defaultCustomerType') // if not login we use the default customer type that come from homepage api 
    const { data } = await client.query({
      query: getDefaultCustomerType,
      variables:{
        "longitude": "77.59104670126453",
        "latitude": "13.04269368886535",
      },
      fetchPolicy: 'network-only',
    });
    let defaultCustomerType = data?.queryDefaultCustomerType?.customerType // if not login we use the default customer type 
    setDefaultCustomerType(defaultCustomerType)
  
    setDefaultCustomerType(defaultCustomerType)
  }

  React.useEffect(() => {
    intialLoad();
  }, []);
  
   const intialLoad = async()=>{
    await verifyLoginInfo()
    await callCartData(cartItems,loginInfo,location)
   }

  const onRefresh = () => {
    isLoading = true
    setIsLoading(isLoading)
    refreshing = true
    setRefreshing(refreshing);
 
    if (selectedCategory) {
      isLoading = false
      setIsLoading(isLoading)
      refreshing = false
      setRefreshing(false)
      refetch({
        variables: {
          "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
          "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
          "rows": 10,
          "columns": 10,
          "subCategoryId": selectedCategory?.itemId
        }
      })
    }
  }
  let image ='https://cdn.nativenest.in/pro/logo/Native-Nest-Trans-Logo.png'

  const openPremuimBanner = useCallback(async (value) => {
    premuimBanner = value
    setPremuimBanner(premuimBanner)
  },[])

  return (
    <>
      <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
        <div className="flex flex-row">
            {/* Left Side - Categories */}
            <div className="overflow-y-scroll hide-scroll-bar border-r border-gray-300 max-h-screen w-1/4  sm:w-1/4 md:w-auto lg:w-auto cursor-pointer">
                    {/* {categories?.map((item,index) => ( */}
                    {console.log("data?.querySubCategories?.items",data?.querySubCategories?.items)}
                    {data?.querySubCategories?.items?.map((item,index) => (
                    <div onClick={()=>selectItem(item)} key={item.id} style={{background:selectedCategory==index?colors?.appThemeColorVeryLight:'white'}} className="p-2 bg-white mx-2  my-2 text-center justify-center rounded-lg border border-gray-300 shadow-md hover:shadow-xl  transition-shadow duration-300 ease-in-out">
                            {(<div className="h-14 sm:h-14 md:h-18 lg:h-24 xl-28 w-full overflow-hidden" ><img  src={item?.image?item?.image:image} className=" w-full h-full object-contain" alt="Item"/></div>)} 
                            {item?.name && <div className='pt-1 text-center flex justify-center items-center'><text  className='line-clamp-2 text-xs sm:text-xs md:text-sm  lg:text-sm  xl:text-sm text-appThemeColorVeryDark leading-tight'>{item?.name}</text></div>}
                    </div>
                    ))}
            </div>

            {/* Right Side - Items */}
            <div className="flex flex-wrap overflow-y-scroll hide-scroll-bar max-h-screen w-3/4 ">
                {SCData?.queryProductsBySubCategory?.displayItems?.items?.map((item)=>(<div className='bg-white m-2 rounded-lg'><GroceryItemCard openPremuimBanner={openPremuimBanner} generalCustomerType={generalCustomerType} isLocationServicable={SCData?.queryProductsBySubCategory?.isLocationServicable} customerType={loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType} cartItems={cartItems} addToCart={addToCart} removeFromCart={removeFromCart} item={item} /></div>))}
            </div>
            {premuimBanner && <PremuimBanner Colors={Colors} colors={colors} banner={premuimBanner} openPremuimBanner={openPremuimBanner} />}
        </div>
        <div className='mt-5'>
          <Footer />
        </div>
    </div>
    </>
  )
}
