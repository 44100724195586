import React, { useCallback, useRef, useEffect, useContext } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { FaLocationDot } from "react-icons/fa6";
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';


const libraries = ["places"];

const GoogleMapsLocation = ({ coordinates, onRegionChangeComplete, onPanDrag }) => {  
  
  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme]; 
 
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  var mapRef = useRef();

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    maxZoom: 25,
    styles: appColorTheme=='systemDefault'?systemThemeMode:appColorTheme,
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  useEffect(() => {
    if (isLoaded && mapRef.current && coordinates && window.google && window.google.maps.marker && window.google.maps.marker.AdvancedMarkerElement) {
      const markerElement = document.createElement('div');
      markerElement.innerHTML = `
        <div className="cursor-pointer text-green-600 text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl text-${colors?.heading}">
          hello
        </div>`;

      const advancedMarker = new window.google.maps.marker.AdvancedMarkerElement({
        map: mapRef.current,
        position: coordinates,
        content: markerElement,
      });

      return () => {
        advancedMarker.map = null; // Clean up the marker on unmount
      };
    }
  }, [isLoaded, coordinates, colors]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded || !coordinates) return <div>Loading Maps...</div>; // Early return for missing coordinates

  return (
    <div className="border-2 rounded h-full w-full" style={{borderColor: Colors.colorTheme[colors?.buttonBackground]}}>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{  width: '100%',height: '100%',position:'relative'}}
        zoom={coordinates?.zoom}
        center={coordinates}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDragEnd={() => {
          if (mapRef.current) {
            const newCenter = mapRef.current.getCenter().toJSON();
            onRegionChangeComplete(newCenter);
          }
        }}
        onDrag={onPanDrag}
      >
        <FaLocationDot style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }} className={`absolute cursor-pointer text-appThemeColorDark text-3xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl `} />
      </GoogleMap>
    </div>
  );
};

export default GoogleMapsLocation;






// import React from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//   width: '400px',
//   height: '400px'
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523
// };

// function GoogleMapsLocation() {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
//   })

//   const [map, setMap] = React.useState(null)

//   const onLoad = React.useCallback(function callback(map) {
//     // This is just an example of getting and using the map instance!!! don't just blindly copy!
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);

//     setMap(map)
//   }, [])

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={10}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//       >
//         { /* Child components, such as markers, info windows, etc. */ }
//         <></>
//       </GoogleMap>
//   ) : <></>
// }

// export default React.memo(GoogleMapsLocation)