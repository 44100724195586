

const colorTheme = {
    primary: "#C4DAFE",
    white: '#FFFFFF',
    whiteSmoke: "#f5f5f5",
    // cardBackground:"#fce4d4",
    // cardBackground:"#fffde2", //#FFFDD0 cream background
    cardBackground:"#e8f9de", //light green background
    // cardBackground:"#f4f7fb",
    creamBackground:"#fffde2",
    grey:"grey",
    lightGrey:"#C4C7C5",
    activeColor: "#3B82F6",
    black: "black",
    appThemeColorVeryDark:"#34A853",//4
    appThemeColorDark:"#4CAF50",//4
    appThemeColor:"#6CC51D", //3
    appThemeColorAboveLight:"#b5e28e", //3
    appThemeColorLight:"#D9F6C8",//2
    appThemeColorVeryLight:"#D6EEDD",//1
    appThemeColorVeryLight1:"#eef8f1",//1
    appNavTheme:"#668630",
    // darkmode
    darkPrimary: "#1A1A1A",
    darkGrey: "#202020",
    // pallateColor3: "#515151",
    pallateColor3: "#262626",
    pallateColor4: "#2C2C2C",
    pallateColor5: "#333333",
    pallateColor6:"#D9D9D9",
    modalBackGround:"rgba(0, 0, 0, .5)",
        // "#A9A9A9"
    // gold1:'#AE8625',
    // gold1:'#ffcf40',
    gold1:'#fff5',
    gold2:'#F7EF8A',
    gold3:'#ffcf40',
    // gold4:'#F7EF8A',
    gold4:'#ffcf40'
        
};

const commonColorUtils = {
    success:"#6CC51D",
    fail:"#F35E52",
    pending:"#FBBB00",
    ...colorTheme,
}


const light = {
    ...commonColorUtils,
    homePageBg : 'appThemeColorVeryLight1',
    textlightGrey : "lightGrey",
    textWhite : "white",
    categorySelected:"appThemeColorAboveLight",
    orderHistoryCardBg:"appThemeColorLight",
    navColor: "white",
    cardsCollectionBg: "white",
    bottomTabBg: "white",
    premiumText: "black",
    heading: "black",
    textColor: "black",
    // textColor: "grey",
    borderColor: "grey",
    cardBackgroundWS: "whiteSmoke",
    backgroundColor: "cardBackground",
    chipBackground: "primary",
    chipText: "activeColor",
    navbarIcon: "activeColor",
    activeChipBackground: "activeColor",
    activeChipText: "white",
    // buttonBackground: "activeColor",
    buttonText: "white",
    iconColor: "appThemeColor",
    secondary:"grey",
    appTextVeryDark:"appThemeColorVeryDark",
    buttonAppThemeDark:"appThemeColorDark",
    cartIconBadge:"appThemeColorDark",
    subChipBackground:"pallateColor6",
    borderSeperation:"pallateColor6",
    subChipActiveBackground: "grey",
    subChipText: "pallateColor5",
    subChipActiveText:"white",
    bottomNavbarItemsopacity:0,
    buttonBackground: "appThemeColor",
    buttonBackgroundDark: "appThemeColorDark",
    addToCartBg:"appThemeColor",
};

const dark = {
    ...commonColorUtils,
    homePageBg : 'darkPrimary',
    textlightGrey : "lightGrey",
    cardsCollectionBg:'modalBackGround',
    textWhite : "white",
    addToCartBg:"pallateColor5",
    orderHistoryCardBg:"pallateColor4",
    buttonBackground: "appThemeColor",
    buttonBackgroundDark: "appThemeColorDark",
    categorySelected:"pallateColor5",
    bottomTabBg:"pallateColor3",
    navColor: "darkPrimary",
    heading: "white",
    premiumText: "black",
    textColor: "lightGrey",
    borderColor: "whiteSmoke",
    backgroundColor: "pallateColor3",
    cardBackgroundWS: "pallateColor3",
    chipBackground: "pallateColor5",
    chipText: "white",
    navbarIcon: "grey",
    activeChipBackground: "#4e4e4e",
    activeChipText: "white",
    // buttonBackground: "whiteSmoke",
    buttonText: "darkPrimary",
    iconColor: "white",
    cartIconBadge:"white",
    buttonAppThemeDark:"pallateColor5",
    appTextVeryDark:"lightGrey",
    subChipBackground: "pallateColor6",
    borderSeperation: "grey",
    subChipActiveBackground: "grey",
    subChipText: "pallateColor5",
    subChipActiveText: "white",
    bottomNavbarItemsopacity:0.8

};

export default {dark,light,colorTheme};