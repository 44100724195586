import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import { QueryOrderTransactionStatus, GetOrderDetailsById } from "../../apollo/queries";
import { gql, useLazyQuery } from "@apollo/client";
import { RiBarChart2Fill } from "react-icons/ri";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { RiShoppingBag4Fill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import CheckOrderStatus from '../../utils/CheckOrderStatus';
import CheckPaymentStatus from '../../utils/CheckPaymentStatus';
import CheckRefundStatus from '../../utils/CheckRefundStatus';
import CheckPaymentMethod from '../../utils/CheckPaymentMethod';
import moment from 'moment';
import UseCookie from '../../utils/cookies/useCookie';

const QueryOrderTrnStatus = gql`
  ${QueryOrderTransactionStatus}
`;

const getOrderDetailsByOrderId = gql`
  ${GetOrderDetailsById}
`;

export default function OrderTransactionStatus(props) {
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
    var [transactionData,setTransactionData] = useState({})
    var [orderDetailsData, setOrderDetailsData] = useState({});
    const { id } = useParams();
    const { t } = useTranslation()
    const navigateScreenTo = useNavigate();
    const { getCustomCookie, } = UseCookie();


    const [fetchOrderdetails, { data, loading, error }] = useLazyQuery(QueryOrderTrnStatus, {
        fetchPolicy: 'network-only',
        onCompleted: (fetchMoreResult) => {
          console.log("🚀 ~ OrderTransactionStatus ~ fetchMoreResult:", fetchMoreResult?.queryOrderTransactionStatus)
          getMoreOrderDetails({
            variables: {
                "orderId": fetchMoreResult?.queryOrderTransactionStatus?.orderId
              },
          })
          setTransactionData(fetchMoreResult?.queryOrderTransactionStatus)
        },
      });

    const [getMoreOrderDetails, { data:orderDetails, loading:orderDetailsLoading, error: orderDetailsError }] = useLazyQuery(getOrderDetailsByOrderId, {
        fetchPolicy: 'network-only',
        onCompleted: (fetchMoreResult) => {
        console.log("🚀 ~ OrderTransactionStatus ~ fetchMoreResult:", fetchMoreResult)
        if(fetchMoreResult?.getOrderDetailsById?.items){
            orderDetailsData = fetchMoreResult
            setOrderDetailsData(orderDetailsData)
          }
    },
    });


    React.useEffect(() => {
        console.log('Redirect ID:', id);
        
        const fetchData = async () => {
          try {
            await fetchOrderdetails({variables: {"transactionId": id}})
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [id]);

      const formatOrderDate = (utcDate) => {
        const localDate = moment.utc(utcDate).local();
      
        // Get day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd, ...)
        const dayOfMonth = localDate.format('Do');
      
        // Format date as '30th Apr 2024'
        const formattedDate = localDate.format('Do MMM YYYY');
      
        // Format time as '03:00 pm'
        const formattedTime = localDate.format('hh:mm a');
      
        // Combine formatted date and time
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;
      
        //console.log("🚀 ~ formatOrderDate ~ formattedDateTime:", formattedDateTime)
        return formattedDateTime;
      };

      const priceDetailAsObject = (prices) => {
        let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
        return data
      }    

      const OrderItem = ({item,index}) => {
          let loginInfo = getCustomCookie('loginInfo')
          let customerType = loginInfo?.customerType!=undefined?loginInfo?.customerType:0
          const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(item?.prices), [item]);
    
      return <div key={`_${index}OrderSummary`} className="flex justify-between">
        <p className={`text-${colors?.heading} text-sm`}>{item.quantity} x {item.name} (₹{priceDetailOfAllCustomerTypes[customerType]})</p>
        <p className={`text-${colors?.heading} text-sm`}>
        <span className="line-through">₹{item.quantity * item.mrp}</span> ₹{item.quantity * priceDetailOfAllCustomerTypes[customerType]}
        </p>
      </div>
    }

      return (
        <>
           <div className='flex flex-col items-center justify-center min-h-screen'>
            
            {orderDetailsData?.getOrderDetailsById?<>
              <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
                  <div className={`flex self-start align-left items-left text-md sm:text-md md:text-md lg:text-xl xl:text-xl gap-2 text-${colors?.heading} font-bold`}>
                    <FaArrowLeftLong className={`hover:text-appThemeColorVeryDark cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 transition-transform ease-in-out cursor-pointer duration-200 active:scale-50 text-${colors?.heading}`} onClick={()=>navigateScreenTo("/")} />
                    <div className={`text-md my-1 sm:text-md md:text-lg lg:text-lg xl:text-xl text-${colors?.heading}`}>Order ID - {`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</div>
                  </div>
                  {/* <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}></h1> */}
                  {/* <div>
                      <div className={` border-appThemeColorAboveLight border-2 shadow relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded`} 
                      style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}>

                      <div className='w-full mx-2'>
                        <div className="flex justify-between "> 
                          <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Ordered " +  orderDetailsData?.getOrderDetailsById?.items?.length + " items" +' ('+ CheckOrderStatus(orderDetailsData?.getOrderDetailsById?.orderState?.status)+")"}</span>
                          <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md mr-1 font-bold text-appThemeColorVeryDark`}>{`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</span>
                        </div>
                        <div className="text-left "> 
                          <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order ID : {transactionData?.orderId ? `#${transactionData?.orderId?.slice(-5)}` : " - "}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Status : {transactionData?.orderStatus ? transactionData?.orderStatus : " - "}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Status : {transactionData?.paymentStatus ? transactionData?.paymentStatus : " - "}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Transaction Amount : {transactionData?.amount ? transactionData?.amount : " - "}</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div>
                      <div className={` border-appThemeColorAboveLight border-2 shadow relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded`} 
                      style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}>

                      <div className='w-full mx-2'>
                        <div className="flex justify-between "> 
                          <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Ordered " +  orderDetailsData?.getOrderDetailsById?.items?.length + (orderDetailsData?.getOrderDetailsById?.items?.length>1?' items':' item') +' ('+ CheckOrderStatus(orderDetailsData?.getOrderDetailsById?.orderState?.status)+")"}</span>
                          {/* <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-appThemeColorVeryDark`}>₹{item?.orderAmount}</span> */}
                          <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md mr-1 font-bold text-appThemeColorVeryDark`}>{`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</span>
                        </div>
                        <div className="text-left "> 
                          <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Amount : {"₹" + orderDetailsData?.getOrderDetailsById?.orderAmount}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Paid Amount : {"₹" + orderDetailsData?.getOrderDetailsById?.paidAmount}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Method : {CheckPaymentMethod(orderDetailsData?.getOrderDetailsById?.paymentMethod)}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Status : { CheckPaymentStatus(orderDetailsData?.getOrderDetailsById?.paymentStatus)}</div>
                          {orderDetailsData?.getOrderDetailsById?.refundStatus!=0 && <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Refund Status : { CheckRefundStatus(orderDetailsData?.getOrderDetailsById?.refundStatus)}</div>}
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Date : {formatOrderDate(orderDetailsData?.getOrderDetailsById?.orderDate)}</div>
                          <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Delivery Date : {orderDetailsData?.getOrderDetailsById?.deliveryDate}</div>
                        </div>
                      </div>
                    </div>
                    {orderDetailsData?.getOrderDetailsById?.deliveryAddress?.deliveryAddress && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`relative gap-2 flex flex-row items-center mb-3 p-2 border-appThemeColorAboveLight border-2 shadow bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                        <FaLocationDot className="text-green-600 text-lg sm:text-xl md:text-xl lg:text-xxl xl:text-xxl" />
                        <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.deliveryAddress?.deliveryAddress}</span>
                    </div>}
                    <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-appThemeColorAboveLight border-2 shadow bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded-lg shadow-md p-4 my-2 `}>
                      {orderDetailsData?.getOrderDetailsById?.items?.map((item, index) => (
                          <OrderItem item={item} index={index} />
                      ))}
                      <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                      {orderDetailsData?.getOrderDetailsById?.billDetails && (
                      <>
                          <div className="flex items-center justify-between my-1">
                          <div className="flex items-center">
                              <RiBarChart2Fill className={`text-${colors?.cartIconBadge}`} name="graph-bar" size={12} />
                              <p className={`text-${colors?.heading} text-sm ml-2`}>{t('MRP')}</p>
                          </div>
                          <p className={`text-${colors?.heading} text-sm`}>₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp}</p>
                          </div>
                          <div className="flex items-center justify-between my-1">
                          <div className="flex items-center">
                              <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                              <p className={`text-${colors?.heading} text-sm ml-2`}>
                              {t('Discount')} <span className={`text-${colors?.cartIconBadge}`}>({((parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp - orderDetailsData?.getOrderDetailsById?.billDetails?.itemsTotal) / parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp)) * 100).toFixed(1)}%)</span>
                              </p>
                          </div>
                          <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.totalSaving)}</p>
                          </div>
                          <div className="flex items-center justify-between my-1">
                          <div className="flex items-center">
                              <FaListAlt className={`text-${colors?.cartIconBadge}`} name="list-alt" size={12} />
                              <p className={`text-${colors?.heading} text-sm ml-2`}>{t('ItemsTotalPrice')}</p>
                          </div>
                          <p className={`text-${colors?.heading} text-sm`}> ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsTotal}</p>
                          </div>
                          <div className="flex items-center justify-between my-1">
                          <div className="flex items-center">
                              <MdDeliveryDining className={`text-${colors?.cartIconBadge}`} name="delivery-dining" size={12} />
                              <p className={`text-${colors?.heading} text-sm ml-2`}>{t('DeliveryCharges')}</p>
                          </div>
                          <p className={`text-${colors?.heading} text-sm`}>+ ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.deliveryCharge}</p>
                          </div>
                          <div className="flex items-center justify-between my-1">
                          <div className="flex items-center">
                              <RiShoppingBag4Fill className={`text-${colors?.cartIconBadge}`} name="shopping-bag" size={12} />
                              <p className={`text-${colors?.heading} text-sm ml-2`}>{t('HandlingCharge')}</p>
                          </div>
                          <p className={`text-${colors?.heading} text-sm`}>+ ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.handlingCharge}</p>
                          </div>
                          <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                      </>
                      )}
                      <div className="flex justify-between my-1">
                      <p className={`text-${colors?.heading} text-sm`}>{t('GrandTotal')} ({orderDetailsData?.getOrderDetailsById?.items?.length})</p>
                      <p className={`text-${colors?.heading} text-sm`}>
                          <span className="line-through">₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp}</span><span className='font-bold'> ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.grandTotal }</span>
                      </p>
                      </div>
                  </div>
                </div>
              </div>
              <button className=" flex items-center justify-center bg-appThemeColorVeryDark text-white rounded p-3 m-5" onClick={() => { navigateScreenTo("/") }}>
                  <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mx-1">Back to home page</span>
              </button>
            </>:
            <>
                <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>No Transaction Data Available</div>
            </>}
          </div>
        </>
      );
}