import React, { useContext, useRef, useState } from 'react'
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { UpdateCustomerName } from "../../apollo/mutations";
import { useMutation, gql, useQuery } from "@apollo/client";
import UseCookie from '../../utils/cookies/useCookie';
import { useTranslation } from 'react-i18next';
import { FaArrowLeftLong } from "react-icons/fa6";
import Loader from '../Loader/Loader';
import { LoginInfoContext } from '../../context/loginInfo';
import { FaLocationDot } from "react-icons/fa6";
import { toast } from 'react-toastify';



const UPDATECUSTOMERNAME = gql`
  ${UpdateCustomerName}
`;

export default function AdditionalAddressDetails(props) {
  const {t} = useTranslation()
  const [label, setLabel] = useState('Home');
  const [customerName, setCustomerName] = useState('');
  const [GST, setGST] = useState('');
  var [phoneNo,setPhoneNo] = useState("");
  var [email,setEmail] = useState("");
  var [gender,setGender] = useState("");
  var [nativePlace,setNativePlace] = useState("");
  const [houseNo, setHouseNo] = useState(props?.location?.details?props?.location?.details?.split(",")[0]:" ");
  const [buildingName, setBuildingName] = useState(props?.location?.details?props?.location?.details?.split(",")[1]:" ");
  const [landmarkArea, setLandmarkArea] = useState(props?.location?.details && props?.location?.details?.split(",")[2]?props?.location?.details?.split(",")?.slice(2):" ");
  var [ loginInfo, setLoginInfo ] = useState({});
  // var { loginInfo, setLoginInfo } = useContext(LoginInfoContext);
  const [errors, setErrors] = useState({});
  var [loading, setLoading] = useState(false);
  // var [loginInfo,setLoginInfo] = useState()
  const modalRef = useRef(null);
  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
  const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();

  
  const [updateCustomerName] = useMutation(UPDATECUSTOMERNAME, { onCompleted, onError });
  

  async function onCompleted(data) {
    //console.log("🚀 ~ placeOrder onCompleted ~ placeOrder:", data)
  }

  function onError(errors) {
    //console.log("🚀 ~  placeOrder onError ~ errors:", errors)
  }

  const verifyLoginInfo = async()=>{
    loginInfo = await getCustomCookie('loginInfo')
    // //console.log("🚀 ~ verifyLoginInfo ~ loginInfo:", loginInfo)
    // setLoginInfo(loginInfo)
    setCustomerName(loginInfo?.name)
    setPhoneNo(loginInfo?.phone)
    setGST(loginInfo?.GST)
    setEmail(loginInfo?.email)
    setGender(loginInfo?.gender)
    setNativePlace(loginInfo?.nativePlace)
  }

  React.useEffect(()=>{
  if(props?.moreAddressDetails){
    verifyLoginInfo()
  }
  },[props.moreAddressDetails])


      const saveAddress = async ()=>{
        let details = (houseNo?.trim() + ", "+buildingName?.trim() )+((landmarkArea && landmarkArea?.trim() )? (", "+landmarkArea?.trim()):"")
        //console.log("🚀 ~ saveAddress ~ details:", details)
        loading = true
        setLoading(loading)
        let loginInfo= await getCustomCookie('loginInfo')
        if(!props?.editAddressDetails){
          try{
            let response = await updateCustomerName({ variables:{
              name:customerName.trim(),
              phone:loginInfo?.phone,
              gst:GST?.trim(),
              email:email?.trim(),
              gender:gender?.trim(),
              nativePlace:nativePlace?.trim()
            }})
            //console.log("🚀 126 ~ saveAddress ~ response:", response)
            if(response?.data?.updateCustomerName){
              await setCustomCookie('loginInfo',JSON.stringify({...response?.data?.updateCustomerName,jwtToken:loginInfo?.jwtToken}))
              loginInfo = {...response?.data?.updateCustomerName,jwtToken:loginInfo?.jwtToken};
              // setLoginInfo(loginInfo);
            }
          }catch(error){
          //console.log("🚀 ~ saveAddress ~ error:", error)
            toast.error('Failed to save address, try again!');
          }
        }
        await props?.addNewAddress(label,details,props?.location)
        loading = false
        setLoading(loading)
        props?.OpenAddressDetailsModal(false,loginInfo);
      }

  return (
    <>
      <div>
            <div className={`flex align-center text-md sm:text-md md:text-md lg:text-xl xl:text-xl items-center gap-2 text-${colors?.heading} font-bold`}>
                  <FaArrowLeftLong className={`cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 text-${colors?.heading}`} onClick={()=>props?.OpenAddressDetailsModal(false)} />
                  <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{props?.location?._id?t("editAdditionalAddress"):t('addAdditionalAddress')}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="flex flex-row items-center gap-2 mb-2">
                {['Home', 'Work', 'Hotel', 'Others'].map((item) => (
                  <button
                    key={item}
                    onClick={() => setLabel(item)}
                    style={{backgroundColor:label === item ? Colors?.colorTheme[colors?.buttonBackground] : Colors?.colorTheme[colors?.categorySelected],borderColor: colors?.appThemeColorDark,borderWidth:label === item ?'2px':'0px'}}
                    className={`border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-1 text-center transition duration-200 transform active:scale-90`}
                  >
                    <span style={{color:label === item ? 'white' : colors?.textColor}} className={`text-sm`}>
                      {item}
                    </span>
                  </button>
                ))}
              </div>

              <div style={{backgroundColor:Colors?.colorTheme[colors?.backgroundColor]}} className={`relative flex items-center border-appThemeColorDark border-2  gap-2 mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                <FaLocationDot className="text-green-600 text-lg sm:text-sm md:text-sm lg:text-lg xl:text-lg" />
                <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{props?.location?.deliveryAddress?props?.location?.deliveryAddress:'No Address'}</span>
              </div>

              {!props?.editAddressDetails && <>
                <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                  Receiver Name<span className={`text-${colors?.buttonBackground}`}> *</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Customer Name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm  outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0 rounded p-2 w-full mt-1 mb-4"
                  // className="w-full pr-2 border-none focus:border-none outline-none  ring-0 focus:ring-0 bg-green-100"

                  style={{ color: colors?.heading }}
                />
                {errors.customerName && <span className="text-red-500">{errors.customerName}</span>}

                <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                  Phone Number<span className={`text-${colors?.buttonBackground}`}> *</span>
                </label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Enter Your Phone Number"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  className="opacity-70 border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                  style={{  }}
                />

                <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                  Email <span className={`text-xs text-pallateColor5`}> (Optional)</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                  style={{ }}
                />
                <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                  Gender <span className={`text-xs text-pallateColor5`}> (Optional)</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                  style={{ }}
                />
                <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                  Native Place <span className={`text-xs text-pallateColor5`}> (Optional)</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Native Place"
                  value={nativePlace}
                  onChange={(e) => setNativePlace(e.target.value)}
                  className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                  style={{ }}
                />
                <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                  GST No <span className={`text-xs text-pallateColor5`}> (Optional)</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your GST Number"
                  value={GST}
                  onChange={(e) => setGST(e.target.value)}
                  className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                  style={{ }}
                />
              </>}

              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                House No & Floor<span className={`text-${colors?.buttonBackground}`}> *</span>
              </label>
              <input
                type="text"
                placeholder="Enter house no & floor details"
                value={houseNo}
                onChange={(e) => setHouseNo(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ color: colors?.heading }}
              />
              {errors.houseNo && <span className="text-red-500">{errors.houseNo}</span>}

              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Building & Block No<span className={`text-${colors?.buttonBackground}`}> *</span>
              </label>
              <input
                type="text"
                placeholder="Enter Building & Block No"
                value={buildingName}
                onChange={(e) => setBuildingName(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ color: colors?.heading }}
              />
              {errors.buildingName && <span className="text-red-500">{errors.buildingName}</span>}

              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Landmark & Area Name <span className={`text-xs text-pallateColor5`}> (Optional)</span>
              </label>
              <input
                type="text"
                placeholder="Enter Landmark & Area Name"
                value={landmarkArea}
                onChange={(e) => setLandmarkArea(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ color: colors?.heading }}
              />
              {errors.landmarkArea && <span className="text-red-500">{errors.landmarkArea}</span>}

              <button
                onClick={saveAddress}
                disabled={!buildingName.trim() || !customerName.trim() || !houseNo.trim() || loading}
                className={`bg-${colors?.buttonBackground} text-white rounded p-2 w-full mt-4 ${(!buildingName.trim() || !customerName.trim() || !houseNo.trim() || loading) ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-90'}`}
              >
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span className="font-bold text-md sm:text-md md:text-md lg:text-lg xl:text-lg">
                    {t('saveAddress')}
                  </span>
                )}
              </button>
            </div>
            <Loader loading={loading} />
      </div>
    </>
  );
}
