import React, { useContext, useState } from 'react'
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import Footer from '../Footer/Footer';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';

export default function RefundAndReturnPolicy() {

  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
  //console.log("🚀 ~ PrivacyPolicy ~ systemThemeMode:", systemThemeMode)
  //console.log("🚀 ~ PrivacyPolicy ~ colors:", colors)
  var [data,setData] = useState(
    {
        // title:'Terms and Conditions',
        // description:'These Terms and Conditions govern your use of the services provided by Native Roots Retail Private Limited ("Native Roots," "we," "us," or "our") through our website, mobile application, or any other platform (collectively, the "Services"). By using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services.',
        details:[
            {
                heading:'Returns & refunds',
                info:['a. Products once delivered are non-returnable, except in case the same are damaged, defective, expired or incorrectly delivered and you are requested to read the return policy of each  product listed at the Native Nest Platform before raising any request for return or refund.', 
                'b. All refunds for returns and cancellations will be processed within seven (7) days from the date of return of the product subject to satisfactory checks and the refunds shall be processed in the same manner as they are received, unless refunds have been provided to You in the form of credits, refund amount will reflect in Your account based on respective banks policies. Refunds for products purchased on cash on delivery basis shall be refunded by way of promotional codes which shall expire after thirty (30) days from the date of issue of such promotional codes. Users can opt-in for a promotional code refund for online payments as well. Refund can not be transferred back to any other payment method once the promotional code is initiated.',
                'c. All other promotional codes issued by Company to Customers shall expire within seven (7) days from the date of its issue.',
                'd. Unless otherwise stated and/or as mutually agreed between the Company and Customer, for payments made through electronic means such as debit card, credit card, net banking, wallet etc. the refund shall be made using the same payment mode.',
                'e. All refunds shall be made in Indian Rupees only.'
            ]
            },
        ]
    }
  )

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
        <div className={`pb-1p p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
        <div className="overflow-y-auto p-2.5">
            {/* <div className={`p-4 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                <h1 className="font-bold text-lg mb-1.5" style={{ color: colors?.heading }}>{data?.title}</h1>
                <p className="text-sm text-justify" style={{ color: colors?.heading }}>{data?.description}</p>
            </div> */}
             <div className="relative flex">
                {/* <Lottie className="w-32 mx-auto" animationData={require('../../assets/returnAndRefund1.json')} /> */}
                <Lottie className="w-46 sm:w-32 md:w-52 lg:w-96 xl:w-96 mx-auto" animationData={require('../../assets/returnAndRefund2.json')} />
              </div>
            {
                data.details.map((item, index) => (
                    <div key={`_${index}PrivacyPolicy`} className={`p-4 mt-2.5 rounded-lg shadow-md border border-1 border-${colors?.buttonBackgroundDark} bg-${colors?.backgroundColor}`}>
                        <h2 className="font-bold text-lg" style={{ color: colors?.heading }}>{item?.heading}</h2>
                        {
                            item?.info.map((value, index) => (
                                <p key={`_${index}PrivacyPolicy1`} className="text-left text-sm mt-1.5" style={{ color: colors?.heading }}>{value}</p>
                            ))
                        }
                    </div>
                ))
            }
        </div>
        {/* <Footer/> */}
        </div>
    </>
  )
}
