import React, { useState, useRef, useContext } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { AiOutlineSearch } from 'react-icons/ai';
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';

const libraries = ['places'];

const CustomAutocomplete = ({ onPlaceSelected }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const { systemThemeMode, appColorTheme } = useContext(ThemeContext);
  const colors = Colors[appColorTheme === 'systemDefault' ? systemThemeMode : appColorTheme];

  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const inputRef = useRef(null);

  const fetchPredictions = async (value) => {
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (predictions) => {
      setPredictions(predictions || []);
    });
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const { formatted_address, geometry: { location } } = place;
        onPlaceSelected({
          description: formatted_address,
          coordinates: {
            lat: location.lat(),
            lng: location.lng(),
          },
        });
        setInputValue(formatted_address);
        setPredictions([]);
      }
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      fetchPredictions(value);
    } else {
      setPredictions([]);
    }
  };

  const handleSelectPrediction = (prediction) => {
    fetchPlaceDetails(prediction.place_id);
  };

  if (loadError) {
    return <div>Error loading Data</div>;
  }

  if (!isLoaded) {
    return <div>Loading Data...</div>;
  }

  return (
    <div className="relative w-full">
      <div className={`flex items-center w-full rounded bg-green-100 border-2 `} style={{borderColor: Colors.colorTheme[colors?.buttonBackground]}}>
        <AiOutlineSearch className='text-2xl m-2' />
        <input
          ref={inputRef}
          type="search"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search for a location"
          className="w-full pr-2 py-2 border-none focus:border-none outline-none focus:outline-none ring-0 focus:ring-0 bg-green-100"

        />
      </div>
      {predictions.length > 0 && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
          {predictions.map((prediction) => (
            <div
              key={prediction.place_id}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelectPrediction(prediction)}
            >
              {prediction.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomAutocomplete;
