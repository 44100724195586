import React, { useRef, useState } from 'react'
import { IoClose } from "react-icons/io5";
import { FaCrown } from "react-icons/fa";
import UseCookie from "../../utils/cookies/useCookie";


export default function PremuimBanner({banner, openPremuimBanner,Colors, colors, currentTheme}) { 
    const modalRef = useRef(null);
    var [premuimMessage, setPremuimMessage] = useState("");
    const { getCustomCookie } = UseCookie();


    React.useEffect(() => {
        if(banner){
            fetchMessage()
        }
      }, [banner]);

      const fetchMessage = async()=>{
        let message = await getCustomCookie('premiumMembershipMessage')
        setPremuimMessage(message)
      }

    const openModal = (modalName) => {
        document.addEventListener("mousedown", handleClickOutside);
    };

    const closeModal = () => {
        console.log("welcome")
        openPremuimBanner(false)
        document.removeEventListener("mousedown", handleClickOutside);
    };


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    return (
    <>
        <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="false"
          >
            <div
              className="fixed inset-0 bg-modalBackGround transition-opacity"
              onClick={closeModal}
            ></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                  ref={modalRef}
                  style={{
                    backgroundColor: Colors?.colorTheme[colors?.buttonText],
                  }}
                  className={`relative transform overflow-hidden rounded-lg bg-${colors?.buttonText} dark:bg-${colors?.buttonText} bg-gradient-to-r from-yellow-700 via-yellow-400 to-yellow-600 text-yellow-900 text-left shadow-xl transition-all sm:my-8  w-full sm:w-full sm:max-w-lg`}
                >
                  <IoClose
                    onClick={closeModal}
                    className={`absolute right-3 top-3 sm:right-3 sm:top-3 md:right-4 md:top-4 lg:right-5 lg:top-5 h-4 sm:h-4 md:h-4 lg:h-6 w-4 sm:w-4 md:w-4 lg:w-6  text-black-400 cursor-pointer text-${colors?.heading} transition duration-200 transform active:scale-50`}
                    aria-hidden="true"
                  />
                    <div className="text-center p-4">
                        <div className="max-w-md mx-auto text-center px-4 sm:px-8 py-5 ">
                            <div className="flex justify-center gap-5 items-center">
                                <FaCrown className="text-md sm:text-xl md:text-xl  lg:text-2xl  xl:text-3xl text-yellow-900" />
                                <text className="text-md sm:text-md md:text-lg  lg:text-xl  xl:text-xl  lg:font-bold font-bold tracking-widest">Introducing</text>
                            </div>
                            <text className={`text-md sm:text-md md:text-xl lg:text-2xl xxl:text-2xl font-bold tracking-wide text-black`}>NatievNest Premuim Pass</text>
                            <p className="text-sm sm:text-sm md:text-md lg:text-md xl:text-lg xxl:text-lg text-black">{premuimMessage?premuimMessage:"Get a Premium membership free for three months by placing an order with value Rs.1000 or more…"}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}
