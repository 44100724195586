export const signUp = `mutation CustomerRegister($phoneNumber: String!) {
  customerRegister(phoneNumber: $phoneNumber) {
    _id
    name
    phone
    customerType
    isActive
    jwtToken
    refreshToken
    addresses {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
    permittedPaymentOptions {
      method
      label
    }
    selectedPaymentMethod
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      deliveryAddress {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
      }
    }
  }
}`;

export const signIn = `
mutation CustomerVerifyOTP($phoneNumber: String!, $otp: String!, $longitude: String!, $latitude: String!) {
  customerVerifyOTP(phoneNumber: $phoneNumber, otp: $otp, longitude: $longitude, latitude: $latitude) {
    _id
    name
    phone
    email
    gender
    nativePlace
    customerType
    isActive
    jwtToken
    refreshToken
    addresses {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
    permittedPaymentOptions {
      method
      label
    }
    selectedPaymentMethod
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      deliveryAddress {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
        chargesWaived
      }
    }
    GST
  }
}`;

export const addItemsToCart = `
mutation AddItemsToCart($cartAddInputs: CartAddInputs!) {
  addItemsToCart(cartAddInputs: $cartAddInputs) {
    productId
    skuId
    quantity
    status
  }
}
`;

export const removeItemsToCart = `
mutation RemoveItemFromCart($item: ItemInput!) {
  removeItemFromCart(item: $item)
}
`;

export const placeOrder = `mutation PlaceOrder($paymentMethod: Int!, $itemsTotal: Int!, $grandTotal: Float!) {
  placeOrder(paymentMethod: $paymentMethod, itemsTotal: $itemsTotal, grandTotal: $grandTotal) {
    _id
    items {
      productId
      skuId
      name
      description
      image
      quantity
      mrp
      prices {
        customerType
        sellingPrice
      }
      status
    }
    customerId
    paymentStatus
    paymentMethod
    orderStatus
    paidAmount
    orderAmount
    orderDate
    deliveryDate
    billDetails {
      itemsTotal
      deliveryCharge
      couponDiscount
      grandTotal
      totalSaving
      handlingCharge
      itemSavings
      itemsMrp
    }
    deliveryAddress {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    onlinePaymentStatus
    refundStatus
    isOrderModified
  }
}`;

export const logOut = `
mutation Mutation {
  logoutCustomer
}
`;

export const AddCustomerAddress = `
mutation AddCustomerAddress($addressInput: AddressInput!) {
  addCustomerAddress(addressInput: $addressInput) {
    address {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
  }
}
`;

export const SelectCustomerAddress = `mutation SelectCustomerAddress($addressId: String!) {
  selectCustomerAddress(addressId: $addressId) {
    _id
    name
    phone
    customerType
    isActive
    jwtToken
    refreshToken
    addresses {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
    permittedPaymentOptions {
      method
      label
    }
    selectedPaymentMethod
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      deliveryAddress {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
      }
    }
  }
}`;

export const UpatePushNotificationToken =`
mutation UpatePushNotificationToken($token: String!) {
  upatePushNotificationToken(token: $token)
}
`


export const UpdateCustomerName = `mutation UpdateCustomerName($name: String!, $phone: String, $gst: String, $email: String, $gender: String, $nativePlace: String) {
  updateCustomerName(name: $name, phone: $phone, gst: $gst, email: $email, gender: $gender, nativePlace: $nativePlace) {
    _id
    name
    phone
    email
    gender
    nativePlace
    customerType
    isActive
    jwtToken
    refreshToken
    addresses {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    status
    permittedPaymentOptions {
      method
      label
    }
    selectedPaymentMethod
    cart {
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      deliveryAddress {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
        chargesWaived
      }
    }
    GST
  }
}`

export const UpdateAddressDetails = `mutation UpdateAddressDetails($addressDetails: AddressDetails!, $addressId: String) {
  updateAddressDetails(addressDetails: $addressDetails, addressId: $addressId) {
    _id
    location {
      coordinates
    }
    deliveryAddress
    details
    label
    selected
    isActive
  }
}`

export const SendCustomerSupportEmail = `mutation SendCustomerSupportEmail($name: String!, $phone: String!, $email: String!, $reason: String!, $subject: String!) {
  sendCustomerSupportEmail(name: $name, phone: $phone, email: $email, reason: $reason, subject: $subject)
}`

export const CancelOrder = `mutation CancelOrder($orderId: String) {
  cancelOrder(orderId: $orderId) {
    orderId
    refundStatus
    refundAmount
    refunxTxnId
    message
    orderStatus
  }
}`

export const ChangePaymentToCODForOrder = `mutation ChangePaymentToCODForOrder($orderId: String) {
  changePaymentToCODForOrder(orderId: $orderId) {
    order {
      _id
      items {
        productId
        skuId
        name
        description
        image
        quantity
        mrp
        prices {
          customerType
          sellingPrice
        }
        status
      }
      customerId
      paymentStatus
      paymentMethod
      orderStatus
      paidAmount
      orderAmount
      orderDate
      deliveryDate
      billDetails {
        itemsTotal
        deliveryCharge
        couponDiscount
        grandTotal
        totalSaving
        handlingCharge
        itemSavings
        itemsMrp
      }
      deliveryAddress {
        _id
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        selected
        isActive
      }
      onlinePaymentStatus
      refundStatus
      isOrderModified
    }
    message
  }
}`

export const ApplyCoupon = `mutation ApplyCoupon($code: String) {
  applyCoupon(code: $code) {
    itemsTotal
    deliveryCharge
    grandTotal
    totalSaving
    handlingCharge
    itemSavings
    itemsMrp
    chargesWaived
    couponDiscount
    walletInfo {
      applied
      amount
      message
    }
    couponInfo {
      applied
      amount
      code
      message
      cancellationApplied
    }
    nestInfo {
      applied
      amount
      message
      couponRefundAmount
      couponRefundAccomodated
    }
    cancelledInfo {
      nestInfo {
        applied
        amount
        message
        couponCancellationAmount
        couponCancellationAccomodated
      }
    }
  }
}`

export const RemoveCoupon = `mutation RemoveAppliedCoupon {
  removeAppliedCoupon {
    itemsTotal
    deliveryCharge
    grandTotal
    totalSaving
    handlingCharge
    itemSavings
    itemsMrp
    chargesWaived
    couponDiscount
    walletInfo {
      applied
      amount
      message
    }
    couponInfo {
      applied
      amount
      code
      message
      cancellationApplied
    }
    nestInfo {
      applied
      amount
      message
      couponRefundAmount
      couponRefundAccomodated
    }
    cancelledInfo {
      nestInfo {
        applied
        amount
        message
        couponCancellationAmount
        couponCancellationAccomodated
      }
    }
  }
}`