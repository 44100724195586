import axios from 'axios';
import { toast } from 'react-toastify';

export const getLocationPermission = async () => {
  try {
    const result = await navigator.permissions.query({ name: 'geolocation' });
    if (result.state === 'granted') {
      // Permission granted
      return true;
    } else if (result.state === 'prompt') {
      // Prompt for permission
      return true;
    } else if (result.state === 'denied') {
      // Permission denied, show instructions
      toast.error(
        "Location access is blocked. Please enable location services in your browser settings."
      );
      return false;
    }
  } catch (error) {
    console.error("Error checking geolocation permission:", error);
    return false;
  }
};


export const getCurrentLocationCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          toast.dismiss();
          resolve(coords);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toast.error("You denied the request for location access.");
              break;
            case error.POSITION_UNAVAILABLE:
              toast.error("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              toast.error("The request to get user location timed out.");
              break;
            default:
              toast.error("An unknown error occurred.");
              break;
          }
          reject(error);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export const getLocationDetails = async (coords) => {
  const apiKey = 'AIzaSyAFDdsnnW8wlbClbQYIKx4aLB1Po_Z_N4w';
  const coordinatesValue = `${coords.latitude},${coords.longitude}`;

    // // //console.log("🚀 ~ React.useCallback ~ coordinatesValue:", coordinatesValue)
  // let apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinatesValue}&key=${apiKey}`;

  try {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coords.latitude}&lon=${coords.longitude}`;
    const response = await axios.get(apiUrl);

    const data = response?.data;
    const locationDetailsData = {
      label: "",
      deliveryAddress: data?.display_name,
      latitude: coords.latitude,
      longitude: coords.longitude
    };

    // console.log("🚀 ~ getLocationDetails ~ locationDetailsData:", locationDetailsData);
    return locationDetailsData;
  } catch (error) {
    toast.error(`🚀 ~ getLocationDetails ~ error: ${error.message}`);
    return false;
  }
};
