import React, { useContext, useEffect, useState } from 'react'
import UseCookie from '../../utils/cookies/useCookie';
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { useTranslation } from 'react-i18next';
import { FaLocationDot } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { UpdateCustomerName } from "../../apollo/mutations";
import { toast } from 'react-toastify';
import { useMutation, gql, useQuery } from "@apollo/client";
import { FaArrowLeftLong } from "react-icons/fa6";


const UPDATECUSTOMERNAME = gql`
  ${UpdateCustomerName}
`;

export default function Profile({isChecked}) {
    const {t} = useTranslation() 
    var [loginInfo,setLoginInfo] = useState()
    const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
    const [updateCustomerName,{loading}] = useMutation(UPDATECUSTOMERNAME);
    var [editCustomerDetails,setEditCustomerDetails] = useState("");
    var [customerName,setCustomerName] = useState("");
    var [phoneNo,setPhoneNo] = useState("");
    var [GSTNo,setGSTNo] = useState("");
    var [email,setEmail] = useState("");
    var [gender,setGender] = useState("");
    var [nativePlace,setNativePlace] = useState("");

    useEffect(() => {
        if(isChecked){
            verifyLoginInfo();
        }
        return (()=>{})
      },[isChecked])
    
      const verifyLoginInfo = async()=>{
        loginInfo = await getCustomCookie('loginInfo')
        setLoginInfoDetails(loginInfo)
      }

      const setLoginInfoDetails = (loginInfo)=>{
        if(loginInfo?.addresses && loginInfo?.addresses?.length>0){
          let index = loginInfo?.addresses?.findIndex((value)=>value?.selected==true)
          loginInfo.defaultAddress = {
            label:loginInfo?.addresses[index]?.label,
            deliveryAddress: `${loginInfo?.addresses[index]?.details}, ${loginInfo?.addresses[index]?.deliveryAddress}`,
            latitude: loginInfo?.addresses[index]?.location?.coordinates[1],
            longitude: loginInfo?.addresses[index]?.location?.coordinates[0]
          }
        }
        console.log("🚀 ~ verifyLoginInfo ~ loginInfo:", loginInfo)
        setLoginInfo(loginInfo)
        setCustomerName(loginInfo?.name)
        setPhoneNo(loginInfo?.phone)
        setGSTNo(loginInfo?.GST)
        setEmail(loginInfo?.email)
        setGender(loginInfo?.gender)
        setNativePlace(loginInfo?.nativePlace)
      }
      const updateCustomerDetails = async ()=>{
        try{
          let requestBody = {
            name:customerName?.trim(),
            phone:phoneNo?.trim(),
            gst:(GSTNo?.trim() ?? ''),
            email:email?.trim() ?? '',
            gender:gender?.trim() ?? '',
            nativePlace:nativePlace?.trim() ?? ''
          }
          let response = await updateCustomerName({ variables:requestBody})
          console.log("🚀 ~ updateCustomerDetails ~ response:", response)
          if(response?.data?.updateCustomerName){
            console.log("🚀 ~ updateCustomerDetails ~ response?.data?.updateCustomerName:", response?.data?.updateCustomerName)
            await setCustomCookie('loginInfo',JSON.stringify({...response?.data?.updateCustomerName,jwtToken:loginInfo?.jwtToken}))
            setLoginInfoDetails({...response?.data?.updateCustomerName,jwtToken:loginInfo?.jwtToken})
            editCustomerDetails = false;
            setEditCustomerDetails(editCustomerDetails)
            toast.success("Profile Updated!")
          }
          else{
            toast.error("Failed to update customer details, try again!")
            editCustomerDetails = false;
            setEditCustomerDetails(editCustomerDetails)
          }
        }catch(error){
          console.log("🚀 ~ updateCustomerDetails ~ error:", error)
          toast.error("Failed to update customer details, try again!")
          editCustomerDetails = false;
          setEditCustomerDetails(editCustomerDetails)
        }
      }

      
  return (
    // <div className="container mx-auto my-60 ">
        <div className="relative container mx-auto my-5 bg-cardBackgroundWS overflow-y-scroll hide-scroll-bar max-h-[90vh]">
            {!editCustomerDetails?<>
              <div className="bg-white rounded relative">
              <MdEdit
                  onClick={()=>{editCustomerDetails = true;setEditCustomerDetails(editCustomerDetails)}}
                  className={`absolute right-0 top-0 h-6 sm:h-6 md:h-6 lg:h-6 w-6 sm:w-6 md:w-6 lg:w-6  text-black-400 cursor-pointer text-${colors?.heading} transition duration-200 transform active:scale-50`}
                  aria-hidden="true"
                  />
              {/* <div className="bg-white relative shadow rounded-lg"> */}
                  <div className="flex justify-center">
                          {/* <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110" /> */}
                          <div style={{backgroundColor:Colors?.colorTheme[colors?.navColor],borderColor: Colors?.colorTheme[colors?.buttonBackground]}} className="rounded-full mx-auto w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110 flex items-center justify-center">
                              <span className={`text-4xl text-${colors?.heading}`}>{loginInfo?.name?loginInfo?.name[0]?.toUpperCase():'G'}</span>
                          </div>
                  </div>
                  
                  <div className="mt-2">
                      <h1 className="font-bold text-center text-3xl text-gray-900">{loginInfo?.name}</h1>
                      <p className={`text-center text-sm font-medium text-${colors?.heading}`}>{loginInfo?.phone}</p>
                      <p className="text-center text-sm text-gray-400 font-medium">{`(${loginInfo?.customerType == 0?"General":(loginInfo?.customerType == 0?"Premuim":"xxxxx")})`}</p>
                      <p>
                          <span>
                              
                          </span>
                      </p>
                      {/* <div className="my-5 px-6">
                          <a href="#" className="text-gray-200 block rounded-lg text-center font-medium leading-6 px-6 py-3 bg-gray-900 hover:bg-black hover:text-white">Connect with <span className="font-bold">@pantazisoft</span></a>
                      </div> */}
                      {/* <div className="flex justify-between items-center my-5 px-6">
                          <a href="" className="text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded transition duration-150 ease-in font-medium text-sm text-center w-full py-3">Facebook</a>
                          <a href="" className="text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded transition duration-150 ease-in font-medium text-sm text-center w-full py-3">Twitter</a>
                          <a href="" className="text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded transition duration-150 ease-in font-medium text-sm text-center w-full py-3">Instagram</a>
                          <a href="" className="text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded transition duration-150 ease-in font-medium text-sm text-center w-full py-3">Email</a>
                      </div> */}

                      {/* <div className="w-full">
                          <h3 className="font-medium text-gray-900 text-left px-6">Recent activites</h3>
                          <div className="mt-5 w-full flex flex-col items-center overflow-hidden text-sm">
                              <a href="#" className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3 w-full block hover:bg-gray-100 transition duration-150">
                                  <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full h-6 shadow-md inline-block mr-2" />
                                      Updated his status
                                      <span className="text-gray-500 text-xs">24 min ago</span>
                              </a>

                              <a href="#" className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3 w-full block hover:bg-gray-100 transition duration-150">
                                  <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full h-6 shadow-md inline-block mr-2" />
                                      Added new profile picture
                                      <span className="text-gray-500 text-xs">42 min ago</span>
                              </a>

                              <a href="#" className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3 w-full block hover:bg-gray-100 transition duration-150">
                                  <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full h-6 shadow-md inline-block mr-2" />
                                  Posted new article in <span className="font-bold">#Web Dev</span>
                                  <span className="text-gray-500 text-xs">49 min ago</span>
                              </a>

                              <a href="#" className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3 w-full block hover:bg-gray-100 transition duration-150">
                                  <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full h-6 shadow-md inline-block mr-2" />
                                  Edited website settings
                                  <span className="text-gray-500 text-xs">1 day ago</span>
                              </a>

                              <a href="#" className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3 w-full block hover:bg-gray-100 transition duration-150 overflow-hidden">
                                  <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full h-6 shadow-md inline-block mr-2" />
                                  Added new rank
                                  <span className="text-gray-500 text-xs">5 days ago</span>
                              </a>
                              
                          </div>
                      </div> */}
                  </div>
                  {(loginInfo?.email || loginInfo?.gender || loginInfo?.nativePlace || loginInfo?.GST) && <>
                      <div className="text-left mt-5"> 
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Personal Information"}</span>
                      </div>
                      <div style={{backgroundColor:Colors?.colorTheme[colors?.categorySelected]}} className={`relative flex flex-col mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left border-appThemeColorDark border-2`} >
                          {loginInfo?.email &&<div className="flex flex-row items-center gap-2 p-1p">
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{"Email :"}</span>
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{loginInfo?.email?loginInfo?.email:" -  "}</span>
                          </div>}
                          {loginInfo?.gender &&<div className="flex flex-row items-center gap-2 p-1p">
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{"Gender :"}</span>
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{loginInfo?.gender?loginInfo?.gender:" -  "}</span>
                          </div>}
                          {loginInfo?.nativePlace &&<div className="flex flex-row items-center gap-2 p-1p">
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{"Native Place :"}</span>
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{loginInfo?.nativePlace?loginInfo?.nativePlace:" -  "}</span>
                          </div>}
                          {loginInfo?.GST && <div className="flex flex-row items-center gap-2 p-1p">
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{"GST :"}</span>
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{loginInfo?.GST?loginInfo?.GST:" -  "}</span>
                          </div>}
                      </div>
                  </>}
                  <>
                      <div className="text-left mt-2"> 
                              <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Active " + t('Address') + (loginInfo?.defaultAddress?.label?" (" +loginInfo?.defaultAddress?.label + ")":"")}</span>
                      </div>
                      <div style={{backgroundColor:Colors?.colorTheme[colors?.categorySelected]}} className={`relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left border-appThemeColorDark border-2`} >
                          <div className="flex flex-row items-center gap-2 p-2p">
                              <FaLocationDot className={`cursor-pointer text-green-600 text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl  text-${colors?.heading}`} />
                              <span className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{(loginInfo?.defaultAddress?.details?(loginInfo?.defaultAddress?.details + ", "):"") +(loginInfo?.defaultAddress?loginInfo?.defaultAddress?.deliveryAddress:'No Address')}</span>
                          </div>
                      </div>
                  </>
              </div>
            </>:
            <>
              <div className={`flex text-md sm:text-md md:text-md lg:text-xl xl:text-xl items-center gap-2 text-${colors?.heading} font-bold`}>
                    <FaArrowLeftLong className={`cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 transition duration-200 transform active:scale-50 text-${colors?.heading}`} onClick={()=>{editCustomerDetails = false;setEditCustomerDetails(editCustomerDetails)}} />
                    <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Edit Profile details</div>
              </div>
              <div className="bg-white rounded relative ">
                  <div className="flex justify-center">
                          {/* <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110" /> */}
                          <div style={{backgroundColor:Colors?.colorTheme[colors?.navColor],borderColor: Colors?.colorTheme[colors?.buttonBackground]}} className="rounded-full mx-auto w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110 flex items-center justify-center">
                              <span className={`text-4xl text-${colors?.heading}`}>{loginInfo?.name?loginInfo?.name[0]?.toUpperCase():'G'}</span>
                          </div>
                  </div>
              </div>
              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Customer Name<span className={`text-${colors?.buttonBackground}`}> *</span>
              </label>
              <input
                type="text"
                placeholder="Enter Your Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm  outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0 rounded p-2 w-full mt-1 mb-4"
                // className="w-full pr-2 border-none focus:border-none outline-none  ring-0 focus:ring-0 bg-green-100"
                style={{  }}
              />

              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Phone Number<span className={`text-${colors?.buttonBackground}`}> *</span>
              </label>
              <input
                type="text"
                disabled={true}
                placeholder="Enter Your Phone Number"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                className="opacity-70 border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{  }}
              />

              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Email
              </label>
              <input
                type="text"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ }}
              />
              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Gender
              </label>
              <input
                type="text"
                placeholder="Enter Your Gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ }}
              />
              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                Native Place
              </label>
              <input
                type="text"
                placeholder="Enter Your Native Place"
                value={nativePlace}
                onChange={(e) => setNativePlace(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ }}
              />
              <label className={`font-bold text-sm sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.cartIconBadge}`}>
                GST No
              </label>
              <input
                type="text"
                placeholder="Enter Your GST Number"
                value={GSTNo}
                onChange={(e) => setGSTNo(e.target.value)}
                className="border border-gray-300 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm rounded p-2 w-full mt-1 mb-4 outline-none focus:border-2 focus:border-appThemeColorVeryDark focus:outline-none ring-0 focus:ring-0"
                style={{ }}
              />

              <button
                onClick={updateCustomerDetails}
                disabled={ !phoneNo.trim() || !customerName.trim() || loading}
                className={`bg-${colors?.buttonBackground} text-white rounded p-2 w-full mt-4 ${(!phoneNo.trim() || !customerName.trim() || loading) ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-90'}`}
              >
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span className="font-bold text-md sm:text-md md:text-md lg:text-lg xl:text-lg">
                    {t('UpdateCustomerDetails')}
                  </span>
                )}
              </button>
            </>}
        </div>
  )
}
