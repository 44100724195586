import React, { useEffect } from 'react'
import Lottie from 'lottie-react';


export default function PageNotFound() {

      
  return (
    <>
        <div className="flex items-center justify-center mt-16">
          <div className="flex-col flex items-center justify-center relative">
            <Lottie className="w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mx-auto" animationData={require('../../assets/PageNotFound.json')} />
            <div className="text-md my-1 sm:text-lg md:text-xl lg:text-xl xl:text-2xl text-black font-bold">
              Page Not Found
            </div>
          </div>
        </div>
        {/* <div className="fixed inset-0 flex flex-col items-center justify-center z-50">
            <div className="fixed inset-0 bg-modalBackGround"></div>
              <div className="flex-col flex items-center justify-center relative">
                <Lottie className="w-10 sm:w-16 md:w-28 lg:w-60 xl:w-1/2 mx-auto" animationData={require('../../assets/PageNotFound.json')} />
                <div className={`text-md my-1 sm:text-lg md:text-xl lg:text-xl xl:text-2xl text-black font-bold`}>Page Not Found</div>
              </div>
          </div> */}
    </>
  )
}
