import React from 'react'

export default function CheckOrderStatus(orderStatusCode) {

    if(orderStatusCode == 0){
        return "Initiated"
    }
    else if(orderStatusCode == 1){
        return "Confirmed"
    }
    else if(orderStatusCode == 2){
        return "Packing"
    }
    else if(orderStatusCode == 3){
        return "Order billing"
    }
    else if(orderStatusCode == 4){
        return "Ready for delivery"
    }
    else if(orderStatusCode == 5){
        return "Rider Assigned"
    }
    else if(orderStatusCode == 6){
        return "Out for delivery"
    }
    else if(orderStatusCode == 7){
        return "Delivered"
    }
    else if(orderStatusCode == 8){
        return "Cancelled"
    }
    else if(orderStatusCode == 9){
        return "Out of stock"
    }
    else if(orderStatusCode == 10){
        return "Invalid delivery address"
    }
    else if(orderStatusCode == 12){
        return "No Items Cart"
    }
    else{
        return "Failed"
    }

}

