import React from 'react'

export default function CheckPaymentStatus(paymentStatus) {

    if(paymentStatus == 0){
        return "Pending"
    }
    else{
        return "Completed"
    }

}

// const PAYMENT_STATUS = Object.freeze({
//     PENDING: 0,
//     COMPLETED: 1
//   })