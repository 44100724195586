import React, { useState, useLayoutEffect, useContext, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationContext } from '../../context/Location';
import { CartContext } from '../../context/Cart';
import GroceryItemCard from '../../ui-components/Cards/GroceryItemCard';
import { QueryProductsSearch, QueryDefaultCustomerType, QueryCustomerLandingPage } from "../../apollo/queries";
import { useMutation, gql, useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import UseCookie from '../../utils/cookies/useCookie';
import { useLocation, useNavigate } from 'react-router-dom';
import LandingPageLoading from '../../ui-components/LandingPageLoading/LandingPageLoading';
import Footer from '../Footer/Footer';



const getProductsBySearch = gql`
  ${QueryProductsSearch}
`;

const getDefaultCustomerType = gql`
${QueryDefaultCustomerType}
`;

export default function SearchProducts(props) {


  var [bottomDrawer, setBottomDrawer] = useState(true);
  const client = useApolloClient();
  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];  
  const { cartItems, setCartData, addToCart,removeFromCart } = useContext(CartContext)
  // const navigationObj = useNavigation();
  const { location } = useContext(LocationContext)
  const [search,setSearch] = useState("")
  var [sortedDisplayItems,setSortedDisplayItems] = useState()
  const { t } = useTranslation()
  var [loginInfo,setLoginInfo] = useState()
  const navigateScreenTo = useNavigate()
  const navigationLocation = useLocation();
  const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();
  var [generalCustomerType, setGeneralCustomerType] = useState(false);
  var [defaultCustomerType,setDefaultCustomerType] = useState()




  const [searchDataRefetch, { data, loading, error }] = useLazyQuery(getProductsBySearch, {
    fetchPolicy: 'no-cache',
    onCompleted: async(fetchMoreResult) => {
      //console.log("🚀 ~ SearchProducts ~ fetchMoreResult:", fetchMoreResult?.queryProductsSearch?.displayItems,search)
      if(fetchMoreResult?.queryProductsSearch){
        if(fetchMoreResult?.queryProductsSearch?.displayItems){
          await verifyLoginInfo()
          if(loginInfo){
            generalCustomerType = await getCustomCookie('generalCustomerType')
            console.log("🚀 ~ onCompleted:async ~ generalCustomerType:", generalCustomerType)
            setGeneralCustomerType(generalCustomerType)
          } 
          sortedDisplayItems = [...fetchMoreResult?.queryProductsSearch?.displayItems]?.sort((a, b) => a?.sortOrder - b?.sortOrder);
          setSortedDisplayItems(sortedDisplayItems)
        }
      }
    },
  });

  const verifyLoginInfo = async()=>{
    loginInfo = await getCustomCookie('loginInfo')
    setLoginInfo(loginInfo)
    // let defaultCustomerType =  await getCustomCookie('defaultCustomerType') // if not login we use the default customer type that come from homepage api 
    const { data } = await client.query({
      query: getDefaultCustomerType,
      variables:{
        "longitude": "77.59104670126453",
        "latitude": "13.04269368886535",
      },
      fetchPolicy: 'network-only',
    });
    let defaultCustomerType = data?.queryDefaultCustomerType?.customerType // if not login we use the default customer type 
    setDefaultCustomerType(defaultCustomerType)
  }

  React.useEffect(() => {
    verifyLoginInfo();
  }, []);

  React.useEffect(() => {
    if(navigationLocation.state){
      searchDataRefetch({ variables:{  
        "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
        "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
        "skip": 0,
        "limit": 10,
        "text":navigationLocation.state?navigationLocation.state:""
      }})
    }
    else{
      navigateScreenTo('/')
    }
    // console.log("🚀 ~ SearchProducts ~ navigationLocation.state:", navigationLocation.state)
  }, [navigationLocation.state])


      const ItemsListing = ({item,ListItemIndex}) => {
        return <>
          <div className='bg-white m-1 rounded-lg cursor-pointer'>
            <GroceryItemCard generalCustomerType={generalCustomerType} isLocationServicable={data?.queryProductsSearch?.isLocationServicable} customerType={loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType} cartItems={cartItems} addToCart={addToCart} removeFromCart={removeFromCart} key={ListItemIndex} item={item} />
          </div>
          </>
      }
    


  return <>
    <div className={`my-2 p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
       {loading ?
            <LandingPageLoading/>:
            // <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>:
            (data?.queryProductsSearch?.displayItems.length>0 ?data?.queryProductsSearch?.displayItems?.map((data, index) => {
            // data = {...data,rows:3,columns:3}
            return <>
                  {data?.displayName && (
                      <div className='flex flex-shrink-0 justify-between items-center px-1p'>
                          <span className={`text-md sm:text-md md:text-md lg:text-lg xl:text-lg text-${colors?.heading} font-bold`}>
                              {data.displayName}
                          </span>
                         {data?.displayName == 'CATEGORIES' && <button className='text-sm text-appThemeColorVeryDark font-bold' onClick={()=>{navigateScreenTo('/Categories')}}>
                              See All
                          </button>}
                      </div>
                  )}
                  <div className='flex flex-shrink-0 justify-between items-center '>
                      {data.rows == 1 ? (
                          <div className='flex snap-x overflow-x-auto justify-between hide-scroll-bar '>
                              {data.items.map((item, index) => (
                                  <div key={index + item?.itemId}><ItemsListing item={item} ListItemIndex={index} /></div>
                              ))}
                          </div>
                      ) : (
                          <div className='flex flex-wrap justify-center items-center'>
                              {data.items.map((item, index) => (
                                  <ItemsListing item={item} ListItemIndex={index} />
                              ))}
                          </div>
                      )}
                  </div>
            </>
            }):<>
            <div className="flex h-96 items-center justify-center">
                <p className="text-center">{t('noItemsExistsWith')} "{navigationLocation.state}"</p>
            </div>
            </>)
          }
        <div className='mt-5'>
          <Footer />
        </div>
    </div>
    </>
}